document.addEventListener('DOMContentLoaded', function() {
    createSnowflakes(50); // Number of snowflakes

    function createSnowflakes(num) {
        const container = document.getElementById('snow-container');
        for (let i = 0; i < num; i++) {
            const snowflake = document.createElement('div');
            snowflake.classList.add('snowflake');
            setSnowflakeStyles(snowflake);
            container.appendChild(snowflake);
        }
    }

    function setSnowflakeStyles(snowflake) {
        const size = Math.random() * 5 + 2; // Random size between 2px and 7px
        const animationDuration = Math.random() * 10 + 5; // Random duration between 5s and 15s
        const animationDelay = Math.random() * 5; // Random delay up to 5s

        snowflake.style.width = `${size}px`;
        snowflake.style.height = `${size}px`;
        snowflake.style.left = `${Math.random() * 100}%`; // Random horizontal position
        snowflake.style.animation = `fall ${animationDuration}s linear ${animationDelay}s infinite`;
    }
});
